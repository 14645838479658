import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import FillMultiplae from "./Components/FillViewMultipleStudents";
// import FillMultipleZip from "./Components/FillMultipleZip";
import RootPage from "./RootPage";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import FillMultiplae from './Components/FillPdfForm';
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import reduxStore from "./Components/Data/Store/ReduxStore";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={reduxStore}>
    {/* <FillMultiplae /> */}
    {/* <FillMultiplae /> */}
    {/* <FillMultipleZip /> */}

    <RootPage />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
