export const authorizedUsers = [
  {
    username: "admin@dpsindp.com",
    password: "Admin@2024",
  },
  {
    username: "nursery@dpsindp.com",
    password: "nursery@123",
  },
  {
    username: "lkg@dpsindp.com",
    password: "lkg@123",
  },
  {
    username: "ukg@dpsindp.com",
    password: "ukg@123",
  },
  {
    username: "class1@dpsindp.com",
    password: "class1@123",
  },
  {
    username: "class2@dpsindp.com",
    password: "class2@123",
  },
];
