export const userData = [
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "MITANSH THAKUR",
    section: "B",
    admissionNumber: "I-22/15112",
    rollNumber: "126",
    fatherName: "YOGESH THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. A quam cumque qui sapiente possimus perspiciatis quod laborum voluptatum excepturi natus dolorum modi ipsam quas, ullam consectetur eum, voluptatibus explicabo deleniti saepe exercitationem. Sunt dicta neque nam molestias exercitationem nostrum animi cupiditate, sapiente fugit! Beatae, error suscipit, perspiciatis quaerat impedit cupiditate nesciunt deserunt sed fugit eligendi a facere molestiae asperiores odio? Dolores, quod a nam similique saepe libero cupiditate aut illo. Laborum, totam distinctio eius repellat facilis ab nobis quaerat eaque fuga, perferendis explicabo sint ratione aliquam. Voluptate deserunt maxime sapiente quae, repellendus voluptatem. At molestias sit quae modi aspernatur iste.",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. A quam cumque qui sapiente possimus perspiciatis quod laborum voluptatum excepturi natus dolorum modi ipsam quas, ullam consectetur eum, voluptatibus explicabo deleniti saepe exercitationem. Sunt dicta neque nam molestias exercitationem nostrum animi cupiditate, sapiente fugit! Beatae, error suscipit, perspiciatis quaerat impedit cupiditate nesciunt deserunt sed fugit eligendi a facere molestiae asperiores odio? Dolores, quod a nam similique saepe libero cupiditate aut illo. Laborum, totam distinctio eius repellat facilis ab nobis quaerat eaque fuga, perferendis explicabo sint ratione aliquam. Voluptate deserunt maxime sapiente quae, repellendus voluptatem. At molestias sit quae modi aspernatur iste.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. A quam cumque qui sapiente possimus perspiciatis quod laborum voluptatum excepturi natus dolorum modi ipsam quas, ullam consectetur eum, voluptatibus explicabo deleniti saepe exercitationem. Sunt dicta neque nam molestias exercitationem nostrum animi cupiditate, sapiente fugit! Beatae, error suscipit, perspiciatis quaerat impedit cupiditate nesciunt deserunt sed fugit eligendi a facere molestiae asperiores odio? Dolores, quod a nam similique saepe libero cupiditate aut illo. Laborum, totam distinctio eius repellat facilis ab nobis quaerat eaque fuga, perferendis explicabo sint ratione aliquam. Voluptate deserunt maxime sapiente quae, repellendus voluptatem. At molestias sit quae modi aspernatur iste.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. A quam cumque qui sapiente possimus perspiciatis quod laborum voluptatum excepturi natus dolorum modi ipsam quas, ullam consectetur eum, voluptatibus explicabo deleniti saepe exercitationem. Sunt dicta neque nam molestias exercitationem nostrum animi cupiditate, sapiente fugit! Beatae, error suscipit, perspiciatis quaerat impedit cupiditate nesciunt deserunt sed fugit eligendi a facere molestiae asperiores odio? Dolores, quod a nam similique saepe libero cupiditate aut illo. Laborum, totam distinctio eius repellat facilis ab nobis quaerat eaque fuga, perferendis explicabo sint ratione aliquam. Voluptate deserunt maxime sapiente quae, repellendus voluptatem. At molestias sit quae modi aspernatur iste.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "Vivan THAKUR",
    section: "C",
    admissionNumber: "I-22/15112",
    rollNumber: "22456",
    fatherName: "YOGESH KUMAR ",
    motherName: "KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "MITANSH Chaudhary",
    section: "A",
    admissionNumber: "I-22/15112",
    rollNumber: "323456",
    fatherName: "KUMAR THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "Vansh Vadhera",
    section: "A",
    admissionNumber: "I-22/15112",
    rollNumber: "423456",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vanshnijk Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vasu Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "1 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "Vivek2 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "jnjndfnj Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "jnjnjf Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansjijij Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh8 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh9 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh10 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh11 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh12 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh13 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh14 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh15 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh16 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh2 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh3 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh4 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh5 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh6 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
  {
    profileIMG:
      "https://static.vecteezy.com/system/resources/previews/002/629/904/non_2x/portrait-of-woman-university-student-holding-book-in-studio-grey-background-free-photo.jpg",
    name: "vansh7 Sharma",
    section: "D",
    admissionNumber: "I-22/15112",
    rollNumber: "99999",
    fatherName: "THAKUR",
    motherName: "JYOTI KUMARI",
    principalDesk:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aliquam enim atque natus error? Impedit saepe autem cum, voluptatum excepturi dolorum deserunt tempore nemo suscipit ad culpa fugiat. Tenetur amet voluptates laboriosam aperiam facilis corporis nisi quo autem optio! Quis explicabo odio natus magni neque atque aliquam eveniet assumenda similique non?",
    english: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia",
      },
      second: {
        word: "A",
        Articular: "B",
        proficiency: "C",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
    hindi: {
      first: {
        language: "A",
        written: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
      second: {
        word: "A",
        Articular: "B",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse nihil fuga cumque mollitia ratione ex natus quam a ipsum ducimus.",
      },
    },
  },
];
